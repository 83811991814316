import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["visaStateField", "visaStateElement", "visaIdNumberField", "select"];
    static euMembersValue = { euMembers: Array };

    connect() {
        // Initial toggle on page load
        this.toggleVisaStateRequired();
    }

    toggleVisaStateRequired() {
        const selectedNationality = this.selectTarget.value;
        // Get the list of EU members from the data attribute
        this.euMembersValue = this.selectTarget.dataset.values;
        const isEU = this.euMembersValue.includes(selectedNationality);
        this.visaStateFieldTarget.required = !isEU;
        this.visaIdNumberFieldTarget.required = !isEU;
        // hide the visaStateField
        if (isEU) {
            this.visaStateElementTarget.classList.add("hidden");
        } else
        {
          this.visaStateElementTarget.classList.remove("hidden");
        }
    }

    // Custom action to toggle the required attribute when needed
    toggleRequired() {
        this.toggleVisaStateRequired();
    }
}